<template>
    <div>
        <v-layout row wrap>
            <v-flex xs6 text-xs-left>
                <v-btn v-if="!requested_bill.delivery_confirmed" @click="deliveryDialog = true" small class="animate__animated animate__flash animate__delay-2s elevation-6 white--text ma-1 text-xs-center" round color="teal " >
                    <v-icon left small class="fas fa-thumbs-up mr-1" > </v-icon>
                    Confirm Delivery
                </v-btn>
            </v-flex>
            <v-flex xs6 text-xs-right>
                <v-btn :to="'make_payment/'+requested_bill.id" v-if="requested_bill.status_code != 'Payment Validated' || requested_bill.status_code != 'Interrupted'" small class="animate__animated animate__flash animate__delay-1s  elevation-6 white--text ma-1 text-xs-center" round color="primary darken-2" >
                    <v-icon left small class="fas fa-dollar-sign mr-1" > </v-icon>
                    Make Payment
                </v-btn>
            </v-flex>
            <v-dialog width="500px" v-model="deliveryDialog"> 
                <v-card> 
                    <v-card-title class="headline primary darken-2 white--text" primary-title>Confirm Order Delivery</v-card-title> 
                    <v-card-text class="pa-4">
                        <p>You hereby confirm that you have received your ordered package</p>
                        <p><b>Note:</b> Please do endeavour to allow a review of this delivered items below</p>
                    </v-card-text>

                    <v-card-actions> 
                        <v-btn color="red" class="white--text" @click="confirmDelivery()"> Confirm Delivery </v-btn> 
                        <v-spacer></v-spacer> 
                        <v-btn color="teal" flat @click="deliveryDialog = false" > Cancel </v-btn> 
                    </v-card-actions> 
                </v-card> 
            </v-dialog> 
        </v-layout>

        <v-card color="white" class="elevation-12">
            <v-card-text style="font-size: 12px">
                <h2 class="teal--text">Billing For Order: {{requested_bill.order_id}}</h2> 
                <v-layout row wrap>
                    <v-flex xs6 text-xs-left>
                        <span class="dark--text font-weight-bold">Total Cost:</span><span> {{requested_bill.total_cost}} FCFA</span>
                    </v-flex>
                    <v-flex xs6 text-xs-right>
                        <span class="dark--text font-weight-bold ">Total Paid:</span><span> {{requested_bill.total_paid}} F CFA</span>
                    </v-flex>

                    <v-flex xs6 text-xs-left>
                        <span class="dark--text font-weight-bold ">Order Date:</span><span> {{requested_bill.get_date_str}}</span>
                    </v-flex>
                    <v-flex xs6 text-xs-right v-if="requested_bill.status_code == 'Pending'">
                        <span class="dark--text font-weight-bold">Order Status:</span><span class="red--text"> {{requested_bill.status_code}}</span>
                    </v-flex>
                    <v-flex xs6 text-xs-right v-if="requested_bill.status_code == 'Payment Validated'">
                        <span class="dark--text font-weight-bold">Order Status:</span><span class="teal--text"> {{requested_bill.status_code}}</span>
                    </v-flex>
                    <v-flex xs6 text-xs-right v-if="requested_bill.status_code == 'Interrupted'">
                        <span class="dark--text font-weight-bold">Order Status:</span><span class="red--text"> {{requested_bill.status_code}}</span>
                    </v-flex>

                    <v-flex xs6 text-xs-left>
                        <span class="dark--text font-weight-bold ">Payment Channel:</span><span> {{requested_bill.payment_means}}</span>
                    </v-flex>
                    <v-flex xs6 text-xs-right v-if="requested_bill.payment_confirmed">
                        <span class="dark--text font-weight-bold " >Payment Status:</span><span class="teal--text"> Confirmed</span>
                    </v-flex>
                    <v-flex xs6 text-xs-right v-else>
                        <span class="dark--text font-weight-bold ">Payment Status:</span><span class="red--text"> Pending</span>
                    </v-flex>

                    <v-flex xs6 text-xs-left>
                        <span class="dark--text font-weight-bold">Shipping Location:</span><span> {{requested_bill.shipping_town}} - {{requested_bill.shipping_location}}</span>
                    </v-flex>
                    <v-flex xs6 text-xs-right v-if="requested_bill.delivery_confirmed">
                        <span class="dark--text font-weight-bold " >Delivery Status:</span><span class="teal--text"> Confirmed</span>
                    </v-flex>
                    <v-flex xs6 text-xs-right v-else>
                        <span class="dark--text font-weight-bold ">Delivery Status:</span><span class="red--text"> Pending</span>
                    </v-flex>
                </v-layout>
            </v-card-text>
        </v-card>
        
        <v-divider></v-divider>        
        <h3 class="mt-4 elevation-24 primary darken-2 pa-2 white--text font-weight-bold text-xs-left">({{payments.length}}) My Payments Made</h3>
        <div class="white elevation-6 pa-2" style="max-height: 300px; overflow: auto"> 
            <div color="white" style="" v-for="item in payments" :key="item.id">
                <v-card-text class="pa-1" style="font-size: 12px;">
                    <v-layout row wrap>
                        <v-flex xs6 text-xs-left>
                            <div>
                                <span class="dark--text font-weight-bold">Amount:</span><span> {{item.amount}} F CFA</span>
                            </div>
                        </v-flex>
                        <v-flex xs6 text-xs-right>
                            <span class="dark--text font-weight-bold">Date:</span><span> {{item.get_date_str}}</span>
                        </v-flex>
                        <v-flex xs8 text-xs-left>
                            <span class="dark--text font-weight-bold ">By:</span><span> {{item.payment_means}} </span>
                        </v-flex>
                        <v-flex xs4 text-xs-right v-if="item.confirmed">
                            <span class="dark--text font-weight-bold ">Status: </span><span class="teal--text"> Confirmed</span>
                        </v-flex>
                        <v-flex xs4 text-xs-right v-else>
                            <span class="dark--text font-weight-bold ">Status: </span><span class="red--text"> Pending</span>
                        </v-flex>
                     </v-layout>
                </v-card-text>
            <v-divider></v-divider>
            </div>
        </div>

        <v-divider></v-divider>
        <div class="mt-4" style="max-height: 400px; overflow: auto; "> 
            <h3 class=" elevation-24 primary darken-2 pa-2 white--text font-weight-bold text-xs-left">({{requestedItems.length}}) Items Requested</h3>
            <v-divider></v-divider>
            <template class="" v-for="item in requestedItems">
                <v-hover :key="item.id">
                    <v-card slot-scope="{ hover }" :class="`elevation-${hover ? 4 : 12}`" :to="'/market_place/items/'+item.product_id" color="white" style="border-left: 5px solid #008080!important">
                        <v-card-text class="pt-2 pb-1" style="font-size: smaller">
                            <h2 class="teal--text">
                                <span class="font-weight-bold teal--text">{{item.product}}</span>
                            </h2> 
                            <v-layout row wrap class="pb-0">
                                <v-flex xs6 text-xs-left>
                                    <span class="dark--text font-weight-bold">Total Cost:</span><span> {{item.price}} FCFA</span>
                                </v-flex>
                                <v-flex xs6 text-xs-right>
                                    <span class="dark--text font-weight-bold ">Quantity Demanded:</span><span> {{item.quantity}}</span>
                                </v-flex>
                                <v-flex xs6 text-xs-left>
                                    <span class="dark--text font-weight-bold">Unit Cost:</span><span> {{item.unit_price}} F CFA</span>
                                </v-flex>
                                <v-flex xs6 text-xs-right>
                                    <span class="dark--text font-weight-bold ">Qty in Stock:</span><span> {{item.quantity_left}}</span>
                                </v-flex>
                            </v-layout>
                        </v-card-text>
                    <v-divider></v-divider>
                    </v-card>
                </v-hover>
            </template>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import 'animate.css'

    export default {

        data() {
            return {
                requested_bill: [],
                requestedItems: [],
                payments: [],
                deliveryDialog: false,
                payment_ref: "",
            }
        },

        mounted(){
            // executes these after the page has been mounted
            this.getRequestedBillDetail();
            this.getRequestedItems();
            this.getPayments();

            this.$store.commit('setCloseURL', "/market_place/shopping_bag/items/transactions/")
            document.title = "PettyCash Market Place | Transactions"
        },

        methods:{
            async getRequestedBillDetail(){
                this.$store.commit('setIsLoading', true)
                
                const requested_order_id = this.$route.params.requested_order_id  // get requested_order_id from url and send request to server
                await axios
                    .get('/api/v1/market_place/get/user/requested/bills/'+requested_order_id+'/')
                    .then(response => {
                        this.requested_bill = response.data  // get the data and fill into requested_order
                        this.payment_ref = response.data.payment_ref
                        this.total_cost = this.requested_bill.total_cost
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },
            async getPayments(){
                this.$store.commit('setIsLoading', true)
                
                const requested_order_id = this.$route.params.requested_order_id  // get requested_order_id from url and send request to server
                await axios
                    .get('/api/v1/market_place/get/user/requested/bill/payments/'+requested_order_id+'/')
                    .then(response => {
                        this.payments = response.data  // get the data and fill into requested_order
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },
            async getRequestedItems(){
                this.$store.commit('setIsLoading', true)
                
                const requested_order_id = this.$route.params.requested_order_id  // get requested_order_id from url and send request to server
                await axios
                    .get('/api/v1/market_place/get/user/requested/bill/items/'+requested_order_id+'/')
                    .then(response => {
                        this.requestedItems = response.data  // get the data and fill into requested_order
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },
            async getDeliveryPaymentRef(){
                this.$store.commit('setIsLoading', true)
                
                const requested_order_id = this.$route.params.requested_order_id  // get campaign category from url and send request to server
                await axios
                    .get('/api/v1/market_place/get/order/payment_ref/'+requested_order_id+'/')
                    .then(response => {
                        this.payment_ref = response.data['payment_ref']
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },

            async confirmDelivery(){
                if (this.requested_bill.status_code == 'Pending'){
                    this.$store.commit('setSnackBarMessage', "You can't confirm a delivery which is still pending")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                }
                else {
                    this.$store.commit('setIsLoading', true)
                    const requested_order_id = this.$route.params.requested_order_id  // get campaign category from url and send request to server
                    let formData = new FormData()
                    formData.append('payment_ref', this.payment_ref)
                    await axios
                        .post('/api/v1/market_place/confirm/user/order/'+requested_order_id+'/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                        .then(response => {
                            this.getRequestedBillDetail()
                            this.deliveryDialog = false
                            this.$store.commit('setSnackBarMessage', response.data[0].text)
                            this.$store.commit('setSnackBarColor', response.data[0].color)
                            this.$store.commit('activateSnackBar', true)
                            var navigation = this.$router
                            var store = this.$store  // timers are noted for this
                            if (response.data[0].valid){
                                setTimeout(function(){
                                    navigation.go()  // redirect back to campaign
                                    store.commit('setSnackBarTimeOut', 3000)  // set time out for snack bar to 4 seconds and remember too reset after 
                                }, 3000)
                            }
                        })
                        .catch(error => {
                            this.deliveryDialog = false
                            if (error.response){
                                this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                                this.$store.commit('setSnackBarColor', "red darken-4")
                                this.$store.commit('activateSnackBar', true)
                            }else{
                                this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                                this.$store.commit('setSnackBarColor', "red darken-4")
                                this.$store.commit('activateSnackBar', true)
                            }
                        })
                    this.$store.commit('setIsLoading', false)

                }
            },
        },
    }
</script>

<style scoped>
    .inputfile {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
    .inputfile + label {
        font-size: 14px;
        padding: 5px;
        font-weight: 700;
        color: white;
        background-color: rgb(0, 26, 255);
        display: inline-block;
    }

    .inputfile:focus + label,
    .inputfile + label:hover {
        background-color: rgb(55, 67, 230);
    }
    .inputfile + label {
        cursor: pointer; /* "hand" cursor */
    }
    .inputfile:focus + label {
        outline: 1px dotted #000;
        outline: -webkit-focus-ring-color auto 5px;
    }
    .inputfile + label * {
        pointer-events: none;
    }

</style>

